export function stripProtocol(url: string) {
  return (url || '').replace(/(https?)?(:\/+)?/, '');
}

export function parseSearchParams(queryString = ''): URLSearchParams {
  if (queryString.startsWith('?')) {
    queryString = queryString.slice(1);
  }
  return new URLSearchParams(queryString);
}

export function isValidURL(url: string): boolean {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

export const getSubdomains = (url: string): string[] => {
  if (!url) {
    return [];
  }
  const { hostname } = new URL(url);
  let subdomains = [];

  if (hostname !== null && hostname !== undefined) {
    const parts = hostname.split('.');

    if (parts.length > 2) {
      subdomains = parts.slice(0, parts.length - 2);
    }
  }

  return subdomains;
};
