// @ts-nocheck
/**
 * TypeScript configuration (typings) is not correctly configured for all projects.
 * Consequently, the files are correctly imported but the TS checker emits errors.
 * The above no-check is safe, as webpack will not allow compilation if for example a file is not resolved.
 */
export { default as AlertOctagonIcon } from './alert-octagon.svg';
export { default as ArrowRightIcon } from './arrow-right.svg';
export { default as PlanUpgradeBackground } from './background-plan-upgrade.svg';
export { default as CaretIcon } from './caret.svg';
export { default as CheckCircleIcon } from './check-circle.svg';
export { default as CheckmarkIcon } from './checkmark.svg';
export { default as ClerkLogoIcon } from './clerk-logo.svg';
export { default as ClipboardIcon } from './clipboard.svg';
export { default as HelpCircleIcon } from './help-circle.svg';
export { default as InfoIcon } from './info.svg';
export { default as JwtIcon } from './jwt.svg';
export { default as LoaderIcon } from './loader.svg';
export { default as LogoPlateIcon } from './logo-plate.svg';
export { default as MoreVerticalIcon } from './more-vertical.svg';
export { default as SearchIcon } from './search.svg';
export { default as ServerIcon } from './server.svg';
export { default as SignInCheckIcon } from './signin-check.svg';
export { default as TypeIcon } from './type.svg';
export { default as WarningIcon } from './warning.svg';
export { default as XIcon } from './x-icon.svg';
export { default as BillingModalBackground } from './billing-modal-background.svg';
export { default as RoundWarning } from './round-warning.svg';
export { default as WebhooksIcon } from './webhook.svg';
export { default as Web3Icon } from './web3.svg';
export { default as CautionIcon } from './caution.svg';
export { default as Selector } from './selector.svg';
export { default as Applications } from './applications.svg';
export { default as DocumentSearch } from './document-search.svg';
export { default as ShuffleIcon } from './shuffle.svg';
export { default as PlayIcon } from './play.svg';
