import { TableCellProps } from '@chakra-ui/react';
import { ValueOf } from '@types';

//TODO REMOVE ALL THIS FILE
/**
 * @deprecated
 */
export type BaseSchema<T> = Array<{
  header: string;
  cell?: (data: T) => React.ReactNode;
  tdProps?: TableCellProps;
  key: string;
  enableSorting?: boolean;
}>;

/**
 * @deprecated
 */
export const TableSortingDirections = {
  DESC: '-',
  ASC: '+',
} as const;

/**
 * @deprecated
 */
export type TableSortingDirection = ValueOf<typeof TableSortingDirections>;

/**
 * @deprecated
 */
export type TableSortingData = {
  column: string;
  direction: TableSortingDirection;
};

/**
 * @deprecated
 */
export type TableProps<T> = {
  data: T[];
  tableSchema?: BaseSchema<T>;
  pagination?: React.ReactNode;
  emptyTableFallback?: JSX.Element;
  rowActions?: (data: T) => JSX.Element;
  onRowClicked: (data: T) => void;
  onSorted?: (data: TableSortingData) => void;
  sortingState?: TableSortingData;
};
