import React from 'react';

const SCROLL_DELAY = 600;
const DASHBOARD_HEADER_HEIGHT = 68;

/**
 * In Next.js there seems to be some issues with smooth scrolling behavior on page transitions.
 * Additionally our dashboard layout with the fixed sidebar which takes up the window height, makes it even more cumbersome
 *
 * @export
 * @param {HTMLElement} [referenceElement]
 * @param {boolean} [calculateDashboardHeader] Include the fixed dashboard header on the calculations
 */
export function useSmoothScrollBehavior(
  referenceElement?: HTMLElement,
  calculateDashboardHeader?: boolean,
): void {
  React.useEffect(() => {
    const hash = window.location.hash;
    if (!hash) {
      return;
    }
    window.setTimeout(() => {
      const el = document.getElementById(hash.replace('#', ''));
      if (!el) {
        return;
      }
      const elementBox = el.getBoundingClientRect();
      (referenceElement || window.top).scroll({
        top:
          window.pageYOffset +
          elementBox.top -
          (calculateDashboardHeader ? DASHBOARD_HEADER_HEIGHT : 0),
        behavior: 'smooth',
      });
    }, SCROLL_DELAY);
  }, []);
}
