export async function rudderInitialize(): Promise<any> {
  if (process.env.NEXT_PUBLIC_RUDDERSTACK_WRITEKEY) {
    window.rudderanalytics = await import('rudder-sdk-js');

    window.rudderanalytics.load(
      process.env.NEXT_PUBLIC_RUDDERSTACK_WRITEKEY,
      'https://clerkshehjzyk.dataplane.rudderstack.com',
      {
        integrations: { All: true }, // load call options
      },
    );
  }
}
