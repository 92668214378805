// TODO this is almost a duplicate of packages/clerk-js/src/ui/hooks/useSaml.ts

import type { SamlIdpSlug } from '@clerk/types';
import { SAML_IDPS } from '@clerk/types';

import { svgUrl } from '@utils';

function getSamlProviderLogoUrl(provider: SamlIdpSlug = 'saml_custom'): string {
  return svgUrl(SAML_IDPS[provider]?.logo);
}

function getSamlProviderName(provider: SamlIdpSlug = 'saml_custom'): string {
  return SAML_IDPS[provider]?.name;
}

export const useSaml = () => {
  return {
    getSamlProviderLogoUrl,
    getSamlProviderName,
  };
};
