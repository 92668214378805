import React from 'react';
import type { SWRConfiguration, SWRResponse } from 'swr';
import { default as useSWR } from 'swr';
import { useSession, useOrganization } from '@clerk/nextjs';
import { HTTPError } from '@utils/errors';
import { buildURL, request } from '@utils/api';
import { useNotificationsDispatch } from '@context/Notifications/NotificationsContext';
import { usePaymentRequired } from '@context/PaymentRequired/PaymentRequiredContext';
import { useLocation } from '@hooks/useLocation';
import type { ActiveSessionResource } from '@clerk/types';
import { useToast } from '@hooks';

import type {
  SWRInfiniteResponse,
  SWRInfiniteKeyLoader,
  SWRInfiniteConfiguration,
} from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';

import type {
  SWRMutationResponse,
  SWRMutationConfiguration,
} from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

export type Configuration<T> = SWRConfiguration<T>;

export function useDashboardSWRInfinite<T = any>(
  path: SWRInfiniteKeyLoader,
  options: SWRInfiniteConfiguration = {},
): SWRInfiniteResponse<T, any> {
  const { session, isSignedIn } = useSession();

  //https://swr.vercel.app/docs/error-handling#status-code-and-error-object
  const fetcher = async path => {
    const url = buildURL(path);
    const token = isSignedIn ? await session.getToken() : null;
    const res = await request(url, { token });

    if (!res.ok) {
      const { message, errors } = await res.json();

      const httpError = new HTTPError(
        message || res.statusText,
        res.status,
        errors,
      );

      throw httpError;
    }

    return res.json();
  };

  return useSWRInfinite(path, fetcher, {
    revalidateOnFocus: false,
    errorRetryInterval: 1000,
    ...options,
  });
}

export function useDashboardSWRMutation<T = any, E = any>(
  path: string | (() => string),
  options: SWRMutationConfiguration<T, E> = {},
): SWRMutationResponse<T, any> {
  const { session, isSignedIn } = useSession();

  //https://swr.vercel.app/docs/error-handling#status-code-and-error-object
  const fetcher = async path => {
    const url = buildURL(path);
    const token = isSignedIn ? await session.getToken() : null;
    const res = await request(url, { token });

    if (!res.ok) {
      const { message, errors } = await res.json();

      const httpError = new HTTPError(
        message || res.statusText,
        res.status,
        errors,
      );

      throw httpError;
    }

    if (res.status === 204) {
      return '';
    }
    return res.json();
  };

  return useSWRMutation(path, fetcher, {
    ...options,
  });
}

export function useDashboardSWR<T = any>(
  path: string | (() => string),
  options: SWRConfiguration<T> = {},
): SWRResponse<T, any> {
  const { session, isSignedIn, isLoaded: isSessionLoaded } = useSession();
  const { organization } = useOrganization();
  const pathStr = typeof path === 'function' ? path() : path;
  const url = buildURL(pathStr, { session: session as ActiveSessionResource });

  //https://swr.vercel.app/docs/error-handling#status-code-and-error-object
  const fetcher = async () => {
    const token = isSignedIn ? await session.getToken() : null;
    const res = await request(url, { token });

    if (!res.ok) {
      const { message, errors } = await res.json();

      const httpError = new HTTPError(
        message || res.statusText,
        res.status,
        errors,
      );

      throw httpError;
    }

    return res.json();
  };

  // This is a temporary fix that is being tracked here
  // https://linear.app/clerk/issue/JS-30/investigate-why-swr-does-not-reload-applications-on-org-switch
  const newUrl = organization?.id
    ? `${url}&organization_id=${organization?.id}`
    : url;

  return useSWR(pathStr && isSessionLoaded ? newUrl : null, fetcher, {
    revalidateOnFocus: false,
    errorRetryInterval: 1000,
    ...options,
  });
}

type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

interface MutatorOptions {
  method: HTTPMethod;
}

export function useDashboardCRUD<T>() {
  // TODO: Add withAssertions as the hook might be used outside the <SignedIn/> guard
  const { session, isSignedIn } = useSession();
  // TODO: Remove once v2 is live
  const { isV2 } = useLocation();
  const { showErrorNotification } = useNotificationsDispatch();
  const { showModal } = usePaymentRequired();
  const { showErrorToast } = useToast();

  const mutator = React.useCallback(
    ({ method }: MutatorOptions) =>
      async (path: string, data?: {}): Promise<T> => {
        const url = buildURL(path);
        const token = isSignedIn ? await session.getToken() : null;

        return request(url, { method, token, data }).then(res => {
          if (res.ok) {
            return (
              res.status === 204
                ? Promise.resolve({})
                : res.json().catch(() => Promise.resolve({}))
            ) as Promise<T>;
          }

          if (!res.ok && res.status === 402) {
            const urlLast = url.split('/').pop();
            const isValidatingCloningInstance = /validate_cloning/g.test(
              urlLast,
            );
            // this will trigger the billing modal that lives within the PaymentRequired provider
            return res.json().then(({ errors }) => {
              const unsupportedFeatures = errors[0].meta.unsupported_features;
              showModal({
                features: unsupportedFeatures,
                exceptionForUpgradeModal: isValidatingCloningInstance,
              });

              return res;
            }) as any;
          }

          // TODO: this check is needed in order to gradually refactor errors
          // throughtout the app and not risk making breaking changes
          const urlLast = url.split('/').pop();
          const isNotDisplayConfigCode422 =
            res.status !== 422 && /display_config/g.test(urlLast);
          const isNotThemeCode422 =
            res.status !== 422 && /theme/g.test(urlLast);

          // TODO: we handle global errors only for PATCH methods for now. Remove this when we have handled all cases.
          if (
            !res.ok &&
            isNotDisplayConfigCode422 &&
            isNotThemeCode422 &&
            method === 'PATCH'
          ) {
            showErrorToast('Something went wrong, please try again later.');

            throw new Error();
          }

          // TODO: Catch res.json errors
          return res.json().then(({ message, errors }) => {
            const httpError = new HTTPError(
              message || res.statusText,
              res.status,
              errors,
            );

            const globalError = httpError.getFirstGlobalError();
            if (globalError && !isV2) {
              showErrorNotification(
                globalError.message,
                globalError.long_message,
              );
            }
            throw httpError;
          });
        });
      },
    [],
  );

  const create = mutator({ method: 'POST' });
  const read = mutator({ method: 'GET' });
  const update = mutator({ method: 'PATCH' });
  const del = mutator({ method: 'DELETE' });
  const put = mutator({ method: 'PUT' });

  return {
    create,
    read,
    update,
    del,
    put,
  };
}
