import type { ComponentDefaultProps } from '@chakra-ui/react';
import type { CSSObject } from '@emotion/react';
import { colors } from '@theme/foundations';

const baseStyle: Record<string, CSSObject> = {
  _hover: {
    borderColor: 'primary.500',
  },
  _focus: {
    borderColor: 'primary.500 !important',
    boxShadow: 'none !important',
  },
  _focusVisible: {
    borderColor: 'primary.500 !important',
    boxShadow: 'none !important',
  },
  '&[aria-invalid=true]': {
    borderColor: 'danger.500',
    boxShadow: 'none !important',
  },
};

const defaultProps: ComponentDefaultProps = {
  size: 'md',
};

const sizes: Record<string, CSSObject> = {
  md: {
    fontSize: 'xs',
    fontWeight: 'normal',
    borderRadius: 'md',
    errorBorderColor: colors.danger['500'],
  },
};

export const Textarea = {
  baseStyle,
  defaultProps,
  sizes,
};
