import React from 'react';
import type { HTTPError } from '../utils/errors';

export type ErrorsResponse = [
  React.Ref<HTMLFormElement>,
  (error: HTTPError) => void,
  () => void,
] & {
  formRef: React.Ref<HTMLFormElement>;
  showErrors: (error: HTTPError) => void;
  hideErrors: () => void;
};

export function useShowSectionValidationErrors(
  errorClass = '.error',
  errorSelectorClass = '.error-msg',
) {
  const formRef = React.useRef(null);
  const className = React.useMemo(() => errorClass.replace('.', ''), [
    errorClass,
  ]);

  const showErrors = React.useCallback(
    error => {
      if (formRef.current) {
        error.getFieldErrorsNames().forEach(field => {
          const cardSubtitleEl: HTMLParagraphElement = (formRef.current as HTMLElement)
            .querySelector(`[name=${field}]`)
            ?.closest('fieldset')
            ?.querySelector(errorSelectorClass);

          if (cardSubtitleEl) {
            cardSubtitleEl.classList.add(className);
            cardSubtitleEl.scrollIntoView({ block: 'center' });
          }
        });
      }
    },
    [formRef, className],
  );

  const hideErrors = React.useCallback(() => {
    (formRef.current as HTMLElement)
      .querySelectorAll(errorClass)
      .forEach(el => {
        el.classList.remove(className);
      });
  }, [formRef, className]);

  const result = [formRef, showErrors, hideErrors] as ErrorsResponse;

  result.formRef = result[0];
  result.showErrors = result[1];
  result.hideErrors = result[2];

  return result;
}
