import React, { lazy, Suspense } from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { ChakraProvider } from '@context/ChakraContext';
import type { LayoutProps } from '../types';

import { useLocation } from '@hooks';
import { PaymentRequiredProvider } from '@context/PaymentRequired/PaymentRequiredProvider';
import { useHeaderBanner } from './useHeaderBanner';

const HeaderLayout = lazy(() =>
  import(/* webpackChunkName: "HeaderLayout" */ './HeaderLayout').then(m => ({
    default: m.HeaderLayout,
  })),
);

const Sidebar = lazy(() =>
  import(/* webpackChunkName: "SideBar" */ '@components/sidebar').then(m => ({
    default: m.Sidebar,
  })),
);

export function Layout({ children }: LayoutProps): JSX.Element {
  const { isHome, pathname } = useLocation();
  const { hasBanner } = useHeaderBanner();

  const isVercelFlow = pathname.startsWith('/vercel');

  const homeLayout = isHome && (
    <Flex
      width='100%'
      flex={1}
      pt={hasBanner ? 16 : 12}
      pb={12}
      px={{ base: 6, md: 16 }}
      height='100%'
      flexDirection='column'
      alignItems='center'
      maxHeight='100%'
    >
      <Flex
        flex={1}
        flexDirection='column'
        maxHeight='100%'
        width='100%'
        as='main'
        height='100%'
        p={0}
        position='relative'
        maxW='container.xxl'
      >
        {children}
      </Flex>
    </Flex>
  );

  const layout = !isHome && (
    <Container
      p={12}
      pt={hasBanner ? 16 : 12}
      maxW='container.xxl'
      display='flex'
    >
      <Suspense fallback={<SideBarFallback />}>
        <Sidebar />
      </Suspense>

      <Container as='main' maxW='container.lg'>
        <RemountOnApplicationInstanceSwitch>
          {children}
        </RemountOnApplicationInstanceSwitch>
      </Container>
    </Container>
  );

  return (
    <ChakraProvider>
      <PaymentRequiredProvider>
        <Flex direction='column' minH='100vh' align='stretch'>
          <Suspense fallback={<HeaderLayoutFallback />}>
            {!isVercelFlow && <HeaderLayout />}
          </Suspense>
          {homeLayout}
          {layout}
        </Flex>
      </PaymentRequiredProvider>
    </ChakraProvider>
  );
}

const HeaderLayoutFallback = () => {
  return (
    <Box
      as='header'
      pos='sticky'
      w='full'
      height={101}
      borderBottom='1px solid'
      borderColor='gray.200'
    />
  );
};

const SideBarFallbackBigBox = () => (
  <Box
    width={'80%'}
    height={'36px'}
    backgroundColor='gray.50'
    borderRadius={'md'}
  />
);

const SideBarFallbackSmallBox = () => (
  <Box
    width={20}
    height={'10px'}
    backgroundColor='gray.50'
    borderRadius={'md'}
  />
);
const SideBarFallback = () => {
  return (
    <Flex as={'aside'} mr={12} direction={'column'} width={'318px'} gap='10'>
      <Flex direction={'column'} w={'full'} gap='3'>
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
      </Flex>

      <Flex direction={'column'} w={'full'} gap='3'>
        <SideBarFallbackSmallBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
      </Flex>

      <Flex direction={'column'} w={'full'} gap='3'>
        <SideBarFallbackSmallBox />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
      </Flex>

      <Flex direction={'column'} w={'full'} gap='3'>
        <Box
          width={20}
          height={'10px'}
          backgroundColor='gray.100'
          borderRadius={'md'}
        />
        <SideBarFallbackBigBox />
        <SideBarFallbackBigBox />
      </Flex>
    </Flex>
  );
};

function RemountOnApplicationInstanceSwitch(
  props: React.PropsWithChildren<{}>,
) {
  const { applicationId, instanceId } = useLocation();
  const key = applicationId + instanceId;
  return <React.Fragment key={key}>{props.children}</React.Fragment>;
}
