import type { InstanceKey } from '@types';

export const getValueToCopy = (
  framework: { publishable_key?: string; secret_key?: string; url?: string },
  pk: InstanceKey,
  sk: InstanceKey,
  url: string,
): string => {
  let value = `${framework.publishable_key}=${pk?.secret}`;

  if (framework.secret_key) {
    value += `\n${framework.secret_key}=${sk?.secret}`;
  }

  if (framework.url) {
    value += `\n${framework.url}=${url}`;
  }

  return value;
};
