import React from 'react';
import { Notification } from '@clerk-ui/components/notification';
import {
  NotificationDispatchContext,
  NotificationStateContext,
} from './NotificationsContext';
import type { NotificationData, NotificationsDispatchContext } from './types';

export function NotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [notification, setNotification] =
    React.useState<NotificationData>(null);

  const dispatchValue: NotificationsDispatchContext = React.useMemo(() => {
    return {
      showNotification: notification => setNotification(notification),
      clearNotification: () => setNotification(null),
    };
  }, [notification]);

  React.useEffect(() => {
    let timer;
    if (notification?.duration > 0) {
      timer = setTimeout(() => {
        setNotification(null);
      }, notification?.duration);
    }
    return () => clearTimeout(timer);
  }, [notification]);

  const handleCloseNotificationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setNotification(null);
  };

  return (
    <NotificationStateContext.Provider value={notification}>
      <NotificationDispatchContext.Provider value={dispatchValue}>
        {notification && (
          <Notification
            title={notification.title}
            message={notification.message}
            flavor={notification.flavor}
            handleCloseClick={handleCloseNotificationClick}
          />
        )}
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}
