import type { ChakraTheme, ThemeConfig } from '@chakra-ui/react';
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import type { Styles } from '@chakra-ui/theme-tools';
import foundations from './foundations';
import components from './components';

const config: ThemeConfig = {
  initialColorMode: 'light',
};

const styles: Styles = {
  global: () => ({
    body: {
      fontFamily: '"Inter", system-ui, sans-serif',
      color: 'black',
      // TODO: Make this dynamic before adding dark theme
      background: 'linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 30%) no-repeat',
    },
  }),
};

const themeOverrides: Partial<ChakraTheme> = {
  styles,
  config,
  components,
  ...foundations,
};

const theme = extendTheme(
  themeOverrides,
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

export default theme;
