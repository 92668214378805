import type {
  ComponentDefaultProps,
  ComponentStyleConfig,
  SystemStyleObject,
} from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  textTransform: 'none',
  borderRadius: 'base',
  fontWeight: 'medium',
};

const sizes = {
  sm: {
    py: '1',
    px: '1.5',
    fontSize: 'xxs',
    lineHeight: 'xxs',
  },
  md: {
    py: '0.5',
    px: '1.5',
    fontSize: 'xs',
    lineHeight: 'xs',
  },
};

const defaultProps: ComponentDefaultProps = {
  variant: 'subtle',
  size: 'md',
};

const variants: SystemStyleObject = {
  blue: {
    bg: 'primary.100',
    color: 'primary.500',
  },
};

export const Badge: ComponentStyleConfig = {
  baseStyle,
  sizes,
  defaultProps,
  variants,
};
