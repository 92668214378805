export function getCSP(nonce: string): string {
  const { hostname: dapiHostname } = new URL(
    process.env.NEXT_PUBLIC_DASHBOARD_API || '',
  );

  // This is for the 307 Cookie dropping flow
  const clerkDashboardApiOrigin = `https://clerk.${dapiHostname}`;
  const monacoEditorCdnFixedVersion = [
    'https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/',
    'https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/',
  ];

  const tldtsCdnFixedVersion = 'https://cdn.jsdelivr.net/npm/tldts@5/';

  const clerkBapiApiOrigin = `https://api.${dapiHostname
    .split(/\./)
    .slice(-2)
    .join('.')}`;

  const childSrc = [
    // Intercom
    'https://intercom-sheets.com',
    'https://www.intercom-reporting.com',
    'https://www.youtube.com',
    'https://player.vimeo.com',
    'https://fast.wistia.net',

    // Sentry
    'https://js.sentry-cdn.com',

    // Svix
    'https://app.svix.com',

    // Calendly
    'https://calendly.com',

    // Hotjar,
    'https://vars.hotjar.com',
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const connectSrc = [];

  const defaultSrc = [
    "'self'",

    // Google tag manager
    //
    // https://developers.google.com/tag-manager/web/csp#custom_javascript_variables
    "'unsafe-eval'",

    // This is for firefox
    "'unsafe-inline'",

    // Clerk
    'https://clerk.com',
    process.env.NEXT_PUBLIC_CLERK_FRONTEND_API,
    process.env.NEXT_PUBLIC_DASHBOARD_API,
    process.env.NEXT_PUBLIC_IMG_SERVICE_URL,
    clerkDashboardApiOrigin,
    clerkBapiApiOrigin,

    // Google tag manager
    //
    // https://developers.google.com/tag-manager/web/csp#enabling_the_google_tag_manager_snippet
    // https://dev.to/matijamrkaic/using-google-tag-manager-with-a-content-security-policy-9ai
    `'nonce-${nonce}'`,
    'https://*.googletagmanager.com',

    // https://developers.google.com/tag-platform/tag-manager/web/csp
    'https://*.google-analytics.com',
    'https://*.analytics.google.com',
    'https://*.g.doubleclick.net',
    'https://*.google.com',
    'https://tagmanager.google.com',

    // TLD Parser library via dynamic imports
    'https://unpkg.com',

    // Intercom
    //
    // https://www.intercom.com/help/en/articles/3894-using-intercom-with-content-security-policy
    'https://app.intercom.io',
    'https://widget.intercom.io',
    'https://js.intercomcdn.com',
    'https://api-iam.intercom.io',
    'https://api-ping.intercom.io',
    'https://nexus-websocket-a.intercom.io',
    'https://nexus-websocket-b.intercom.io',
    'wss://nexus-websocket-a.intercom.io',
    'wss://nexus-websocket-b.intercom.io',
    'https://uploads.intercomcdn.com',
    'https://uploads.intercomusercontent.com',

    ...monacoEditorCdnFixedVersion,
    tldtsCdnFixedVersion,

    // Sentry
    'https://js.sentry-cdn.com',
    'https://browser.sentry-cdn.com/',
    'https://*.ingest.sentry.io',

    // Plausible
    'https://plausible.io',

    // Hotjar
    'https://*.hotjar.com',
    'wss://*.hotjar.com',
    'http://content.hotjar.io',

    // Segment
    'https://cdn.segment.com',
    'https://api.segment.io',
    'https://scdn.clerk.com',
    'https://segapi.clerk.com',

    // Rudderstack
    'https://api.rudderlabs.com',
    'https://clerkshehjzyk.dataplane.rudderstack.com',

    // GitHub API
    'https://api.github.com',

    // HubSpot
    'https://*.hs-scripts.com',
    'https://*.hs-banner.com',
    'https://*.hscollectedforms.net',
    'https://*.hs-analytics.net',
    'https://*.hubspot.com',

    // Dev and Stg instances for local, staging and production environments
    '*.lcl.dev',
    '*.stg.dev',
    '*.lclstage.dev',
    '*.stgstage.dev',
    '*.lclclerk.com',
  ];

  // Next.js live reload
  if (process.env.NODE_ENV === 'development') {
    defaultSrc.push('wss:');
  }

  // Clerk for custom Clerk JS origin
  if (process.env.NEXT_PUBLIC_CLERK_JS) {
    const { hostname: clerkJSHostname } = new URL(
      process.env.NEXT_PUBLIC_CLERK_JS,
    );
    const clerkJSOrigin = `https://${clerkJSHostname}`;
    defaultSrc.push(clerkJSOrigin);
  }

  const fontSrc = [
    "'self'",
    'data:',

    'fonts.gstatic.com',

    'https://js.intercomcdn.com',
    'http://fonts.intercomcdn.com',

    ...monacoEditorCdnFixedVersion,
  ];

  const formAction = [
    "'self'",
    'https://intercom.help',
    'https://api-iam.intercom.io',
  ];

  const imgSrc = [
    // TODO: Limit to images.(clerk.dev|clerkstage.dev|lclclerk.com when images are ready
    '*',

    "'self'",
    'data:',
    'blob:',

    // https://developers.google.com/tag-platform/tag-manager/web/csp
    'https://*.googletagmanager.com',
    'https://*.google-analytics.com',
    'https://*.analytics.google.com',
    'https://*.g.doubleclick.net',
    'https://*.google.com',
    'https://ssl.gstatic.com',
    'https://www.gstatic.com',

    'https://js.intercomcdn.com',
    'https://static.intercomassets.com',
    'https://downloads.intercomcdn.com',
    'https://uploads.intercomusercontent.com',
    'https://gifs.intercomcdn.com',
    'https://video-messages.intercomcdn.com',
    'https://messenger-apps.intercom.io',
    'https://*.intercom-attachments-5.com',
    'https://*.intercom-attachments-6.com',
    'https://*.intercom-attachments-9.com',
  ];

  const mediaSrc = ['https://js.intercomcdn.com'];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scriptSrc = [];

  const styleSrc = [
    "'self'",
    'fonts.googleapis.com',
    "'unsafe-inline'",
    ...monacoEditorCdnFixedVersion,
  ];

  const workerSrc = ['blob:'];

  const frameSrc = [
    'https://app.svix.com',
    'https://vars.hotjar.com',
    'https://calendly.com',
  ];

  return (
    ['child-src']
      .concat(childSrc)
      // Fallback to default-src to simplify CSP
      // .concat('; connect-src')
      // .concat(connectSrc)
      .concat('; default-src')
      .concat(defaultSrc)
      .concat('; font-src')
      .concat(fontSrc)
      .concat('; form-action')
      .concat(formAction)
      .concat('; img-src')
      .concat(imgSrc)
      .concat('; media-src')
      .concat(mediaSrc)
      // Fallback to default-src to simplify CSP
      // .concat('; script-src')
      // .concat(scriptSrc)
      .concat(['; style-src'])
      .concat(styleSrc)
      .concat(['; worker-src'])
      .concat(workerSrc)
      .concat(['; frame-src'])
      .concat(frameSrc)
      .join(' ')
  );
}
