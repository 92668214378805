import type { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { cssVar, mode } from '@chakra-ui/theme-tools';
import { textStyles } from '../foundations/textStyles';

const $bg = cssVar('tooltip-bg');

const baseStyle: SystemStyleFunction = props => {
  const bg = mode('gray.700', 'gray.300')(props);
  return {
    [$bg.variable]: `colors.${bg}`,
    color: mode('whiteAlpha.900', 'gray.900')(props),
    borderRadius: 'base',
    ...textStyles['sm-medium'],
    boxShadow: 'md',
  };
};

const defaultProps = {
  gutter: 6,
};

export const Tooltip = {
  baseStyle,
  defaultProps,
};
