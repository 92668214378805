import React from 'react';
import type {
  NotificationData,
  NotificationDispatcher,
  NotificationsDispatchContext,
} from './types';

export const NotificationStateContext =
  React.createContext<NotificationData>(null);
export const NotificationDispatchContext =
  React.createContext<NotificationsDispatchContext>(null);

const showErrorNotification =
  (notificationDispatcher: NotificationDispatcher) =>
  (message?: string, longMessage?: string): void => {
    notificationDispatcher?.({
      duration: 5000,
      flavor: 'error',
      title: 'Oops, something went wrong...',
      message: longMessage || message || 'Please try again.',
    });
  };

const showSuccessNotification =
  (notificationDispatcher: NotificationDispatcher) =>
  (message = 'Action saved'): void => {
    notificationDispatcher?.({
      duration: 3500,
      flavor: 'success',
      title: 'Success!',
      message: message,
    });
  };

function useNotificationsDispatch(): NotificationsDispatchContext & {
  showSuccessNotification: (message?: string) => void;
  showErrorNotification: (message?: string, longMessage?: string) => void;
} {
  const context = React.useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatch must be used within a NotificationsProvider',
    );
  }
  const dispatcher = context?.showNotification;
  return {
    ...context,
    showErrorNotification: showErrorNotification(dispatcher),
    showSuccessNotification: showSuccessNotification(dispatcher),
  };
}

export {
  useNotificationsDispatch,
  showErrorNotification,
  showSuccessNotification,
};
