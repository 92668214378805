import { useDashboardSWR, useInstance, useLocation } from '@hooks';
import { useMemo } from 'react';

export const useHeaderBanner = (): {
  hasPasswordEndpointBanner: boolean;
  hasUserExceededContent: boolean;
  hasGracePeriodBanner: boolean;
  hasGracePeriodBannerExpired: boolean;
  hasBanner: boolean;
} => {
  const { instance } = useInstance();
  const { applicationId } = useLocation();
  const { data: application } = useDashboardSWR(
    applicationId ? `/v1/applications/${applicationId}` : null,
  );

  const hasExceededMau =
    application &&
    Number(application.subscription_plan_mau_limit) &&
    Number(application.current_mau) >
      Number(application.subscription_plan_mau_limit);

  const res = useMemo(() => {
    return {
      hasPasswordEndpointBanner:
        instance?.patch_me_password_state === 'enabled',
      hasUserExceededContent: !!hasExceededMau,
      hasGracePeriodBanner:
        application?.entered_grace_period_at &&
        !application?.grace_period_expired,
      hasGracePeriodBannerExpired:
        application?.entered_grace_period_at &&
        application?.grace_period_expired,
    };
  }, [
    instance?.patch_me_password_state,
    application?.entered_grace_period_at,
    application?.grace_period_expired,
    hasExceededMau,
  ]);

  return {
    ...res,
    hasBanner:
      res.hasPasswordEndpointBanner ||
      res.hasUserExceededContent ||
      res.hasGracePeriodBanner ||
      res.hasGracePeriodBannerExpired ||
      false,
  };
};
