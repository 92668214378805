import type {
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { textStyles } from '@theme/foundations/textStyles';
import type { ComponentDefaultProps } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  ...textStyles['sm-normal'],
  fontFamily: 'sourceCodePro',
  borderRadius: 'base',
  p: 1,
  color: 'black !important', // Is there a better place for this?
};

const variantSubtle: SystemStyleFunction = props => {
  const { colorScheme: c, theme } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  const lightThemeColor = c === 'gray' ? 'black' : `${c}.800`;
  const color = mode(lightThemeColor, `${c}.200`)(props);
  return {
    bg: mode(`${c}.100`, darkBg)(props),
    color,
  };
};

const variants = {
  subtle: variantSubtle,
};

const defaultProps: ComponentDefaultProps = {
  size: 'sm',
  variant: 'subtle',
};

export const Code = {
  baseStyle,
  defaultProps,
  variants,
};
