import React, { lazy, Suspense } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useInstance, useUnsafeMetadata } from '@hooks';
import type { ModalData } from './types';
import { PaymentRequiredContext } from '@context/PaymentRequired/PaymentRequiredContext';

const BillingModal = lazy(() =>
  import('@components/common/BillingModal').then(m => ({
    default: m.BillingModal,
  })),
);

const InfoBillingModal = lazy(() =>
  import('@components/common/InfoBillingModal').then(m => ({
    default: m.InfoBillingModal,
  })),
);

export function PaymentRequiredProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onUpgradeModalOpen,
    onClose: onUpgradeModalClose,
  } = useDisclosure();
  const [modalInfo, setModalInfo] = React.useState<ModalData>(null);
  const { instance } = useInstance();
  const { shouldShowInfoModal } = useUnsafeMetadata();

  const isDevInstance = instance?.environment_type === 'development';

  const value: PaymentRequiredContext = React.useMemo(() => {
    return {
      showModal: params => {
        const {
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
        } = params || {};
        setModalInfo({
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
        });

        onUpgradeModalOpen();
      },
    };
  }, [onUpgradeModalOpen]);

  // exceptionForUpgradeModal is used in order to handle the exception of showing the
  // billing modal we normally show in production, but in dev instances just before we create a prod instance
  const hasExceptionForUpgradeModal =
    isDevInstance && modalInfo?.exceptionForUpgradeModal;

  const hasSeenBillingModal = !shouldShowInfoModal(instance?.application_id);

  const showInfoBillingModal =
    isDevInstance &&
    !hasSeenBillingModal &&
    !modalInfo?.exceptionForUpgradeModal;

  return (
    <PaymentRequiredContext.Provider value={value}>
      {showInfoBillingModal && (
        <Suspense>
          <InfoBillingModal
            isOpen={isUpgradeModalOpen}
            onClose={onUpgradeModalClose}
            callbackAfterClose={modalInfo?.callbackAfterClose}
          />
        </Suspense>
      )}

      {(!isDevInstance || hasExceptionForUpgradeModal) && (
        <Suspense>
          <BillingModal
            features={modalInfo?.features}
            downgradeAction={modalInfo?.downgradeAction}
            downgradeTo={modalInfo?.downgradeTo}
            isOpen={isUpgradeModalOpen}
            onClose={onUpgradeModalClose}
          />
        </Suspense>
      )}

      {children}
    </PaymentRequiredContext.Provider>
  );
}
